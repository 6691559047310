<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col>
              <div>Paket</div>
            </b-col>
            <b-col>
              <div align="right">
                <b-button
                  variant="outline-warning"
                  v-b-modal.modal-sm
                  :to="{ name: `admin-kategori-paket-tryout` }"
                  class="btn-icon mr-1"
                >
                  <feather-icon icon="ListIcon" class="mr-25" />Kategori
                  Paket</b-button
                >
              </div>
              <b-modal
                v-model="ModalUploadThumbnail"
                id="modal-upload"
                centered
                size="lg"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-row>
                      <b-col md="12">
                        <b-form-group label="Thumbnail" label-for="Thumbnail">
                          <b-form-file
                            id="Thumbnail"
                            v-model="form.thumbnail"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            no-drop
                            accept="image/*"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="UploadThumbnail"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click="ModalUploadThumbnail = false"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
              <b-modal
                v-model="Modal"
                id="modal-tambah"
                centered
                size="lg"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-row>
                      <b-col sm="3">
                        <b-form-group label="Nama Paket" label-for="nama-paket">
                          <b-form-input
                            v-model="form.name"
                            placeholder="Nama Paket"
                            name="nama-paket"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group label="Harga" label-for="harga">
                          <b-form-input
                            v-model="form.price"
                            name="price"
                            @keyup="doFormatRupiah"
                            placeholder="Masukkan harga"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- <b-col sm="3">
                        <b-form-group label="Tanggal Mulai" label-for="info_start">
                          <flat-pickr
                            v-model="form.info_start"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S',
                              time_24hr: true,
                            }"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group label="Tanggal Akhir" label-for="info_end">
                          <flat-pickr
                            v-model="form.info_end"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S',
                              time_24hr: true,
                            }"
                          />
                        </b-form-group>
                      </b-col> -->
                      <b-col sm="12" md="6" lg="2">
                        <b-form-group
                          label="Maks. Peserta"
                          label-for="max_quota"
                        >
                          <b-form-input
                            v-model="form.max_quota"
                            name="max_quota"
                            @keyup="doFormatRupiah"
                            placeholder="Masukkan maksimal peserta"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="6" lg="4">
                        <b-form-group label="Kategori" label-for="kategori">
                          <b-form-select
                            v-model="form.category_id"
                            :options="katpaketData"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12">
                        <b-form-group label="Deskripsi" label-for="description">
                          <b-form-textarea
                            v-model="form.description"
                            name="description"
                            placeholder="Masukkan Deskripsi"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col sm="12" md="6">
                        <b-form-group
                          label="Apakah paket terdapat sub kategori?"
                          label-for="is_contain_subcat"
                        >
                          <b-form-select
                            id="is_contain_subcat"
                            v-model="form.is_contain_subcat"
                            :options="[
                              { text: 'Ya', value: 1 },
                              { text: 'Tidak', value: 0 },
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="6">
                        <b-form-group
                          label="Apakah peserta diharuskan memilih jurusan dan rumpun sebelum pengerjaan?"
                          label-for="is_contain_rumpun"
                        >
                          <b-form-select
                            id="is_contain_rumpun"
                            v-model="form.is_contain_rumpun"
                            :options="[
                              { text: 'Ya', value: 1 },
                              { text: 'Tidak', value: 0 },
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click="Modal = false"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="paketData"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="allowUpdate()"
                      @click="ModalUpload(row.item)"
                    >
                      <feather-icon icon="UploadCloudIcon" class="mr-50" />
                      <span>Upload Thumbnail</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :to="{ path: `paket-tryout/detail/${row.item.id}` }"
                    >
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span>Detail</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="allowUpdate()"
                      @click="ModalUbah(row.item)"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item :to="{ name: 'admin-ebook-detail', params: { id: row.item.id } }">
                        <feather-icon icon="EyeIcon" class="mr-50" />
                        <span>Detail</span>
                      </b-dropdown-item> -->
                    <b-dropdown-item
                      v-if="allowDelete()"
                      @click="ModalHapus(row.item)"
                    >
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-- <b-button
                    title="Upload Thumbnail"
                    variant="outline-warning"
                    class="btn-icon"
                    @click="ModalUpload(row.item)"
                  >
                    <feather-icon icon="CameraIcon"
                  /></b-button>
                  <b-button
                    title="Detail"
                    variant="outline-success"
                    class="btn-icon"
                    :to="{ path: `paket-tryout/detail/${row.item.id}` }"
                  >
                    <feather-icon icon="ListIcon"
                  /></b-button>
                  <b-button
                    title="Ubah"
                    variant="outline-info"
                    class="btn-icon"
                    @click="ModalUbah(row.item)"
                  >
                    <feather-icon icon="EditIcon"
                  /></b-button>
                  <b-button
                    title="Hapus"
                    variant="outline-danger"
                    class="btn-icon"
                    @click="ModalHapus(row.item)"
                  >
                    <feather-icon icon="TrashIcon"
                  /></b-button> -->
                </template>
                <!-- A custom formatted column -->
                <template #cell(tgl)="data">
                  {{ humanDate(data.item.info_start) }} s/d
                  {{ humanDate(data.item.info_end) }}
                </template>

                <template #cell(price)="data">
                  {{ formatRupiah(data.item.price) }}
                </template>
                <template #cell(max_quota)="data">
                  {{ formatRupiah(data.item.max_quota) }}
                </template>

                <template #cell(color)="data">
                  <b-badge :variant="data.item.color">
                    {{ data.item.color }}
                  </b-badge>
                </template>

                <!-- A virtual composite column -->
                <template #cell(name)="row">
                  <b-link
                    class="text-dark"
                    :to="{ path: `paket-tryout/detail/${row.item.id}` }"
                    >{{ row.item.name }}</b-link
                  >
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
              <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BFormTextarea,
  BFormFile,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
  BLink,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BDropdownItem,
    BDropdown,
    BOverlay,
    BFormTextarea,
    BFormFile,
    flatPickr,  
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    BLink,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      paketData: [],
      katpaketData: [],
      form: {
        id: null,
        name: null,
        price: null,
        thumbnail: null,
        description: null,
        info_start: new Date(),
        info_end: new Date(),
        max_quota: null,
        sum_subtest: null,
        is_contain_rumpun: 0,
        is_contain_subcat: 0,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Paket", sortable: true },
        { key: "price", label: "Harga (Rp)", sortable: true },
        { key: "max_quota", label: "Maks. Peserta", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Paket" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Paket",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    doFormatRupiah() {
      this.form.price = this.formatRupiah(this.form.price);
      this.form.max_quota = this.formatRupiah(this.form.max_quota);
      this.form.sum_subtest = this.formatRupiah(this.form.sum_subtest);
    },
    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        description: null,
        thumbnail: null,
        description: null,
        info_start: new Date(),
        info_end: new Date(),
        max_quota: null,
        sum_subtest: null,
      };
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalUpload(item) {
      this.id = item.id;
      this.form = item;
      this.doFormatRupiah();
      this.activeAction = "upload";
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.doFormatRupiah();
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.doFormatRupiah();
      this.titleModal = "Ubah Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("paketTryout/createUpdateDelete", data)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }
      const fd = new FormData();
      fd.append("name", this.form.name);
      fd.append("description", this.form.description);
      fd.append("price", this.unFormatRupiah(this.form.price));
      fd.append("max_quota", this.unFormatRupiah(this.form.max_quota));
      fd.append("category_id", this.form.category_id);
      fd.append("is_contain_subcat", this.form.is_contain_subcat);
      fd.append("is_contain_rumpun", this.form.is_contain_rumpun);
      if (this.id) {
        fd.append("id", this.form.id);
      }

      this.$store
        .dispatch("paketTryout/createUpdateDelete", fd)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.Modal = false;
          this.getData();
          this.displaySuccess({
            text: "Paket berhasil di" + this.activeAction,
          });

          this.form = {
            id: null,
            name: null,
            description: null,
            thumbnail: null,
            description: null,
            info_start: new Date(),
            info_end: new Date(),
            max_quota: null,
            sum_subtest: null,
          };
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    UploadThumbnail() {
      const fd = new FormData();
      fd.append("thumbnail", this.form.thumbnail);
      fd.append("name", this.form.name);
      fd.append("description", this.form.description);
      fd.append("info_start", this.form.info_start);
      fd.append("info_end", this.form.info_end);
      fd.append("price", this.unFormatRupiah(this.form.price));
      fd.append("max_quota", this.unFormatRupiah(this.form.max_quota));
      fd.append("category_id", this.form.category_id);
      if (this.id) {
        fd.append("id", this.form.id);
      }

      this.$store
        .dispatch("paketTryout/createUpdateDelete", fd)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.ModalUploadThumbnail = false;

          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Thumbnail berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getData() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("paketTryout/index", payload)
        .then((response) => {
          this.loading = false;
          let paketData = response.data.data;
          this.paketData = paketData;
          this.totalRows = this.paketData.length;
          this.doFormatRupiah();
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    async getDataKategoriPaket() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let katpaketData = response.data.data;
          katpaketData.unshift({ id: null, name: "- Pilih Kategori Paket -" });
          katpaketData.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.katpaketData = katpaketData;
          // this.totalRows = this.katpaketData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {},
  async mounted() {
    this.getData();
    this.getDataKategoriPaket();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
